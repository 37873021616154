import React, { useState, useEffect } from 'react'
import _pick from 'lodash/pick'
import PropTypes from 'prop-types'
import './SavePlantingControls.scss'

const SavePlantingControls = ({
  currentPlanting,
  form_authenticity_token,
  plannedTrees,
  plantingHasChanged,
  reviewMode,
}) => {
  const [serverErrors, setServerErrors] = useState(null)
  const [serverMessage, setServerMessage] = useState(null)

  const [plantingStatus, setPlantingStatus] = useState('')
  const handleCommitPlanting = async () => {
    const plotCount = new Set(plannedTrees.map((tree) => tree.plot_id)).size
    const confirmText = `Commit planting and create ${plannedTrees.length} trees in ${plotCount} plot${plotCount > 1 ? 's' : ''}?`
    if (!confirm(confirmText)) return // eslint-disable-line no-restricted-globals, no-alert
    setPlantingStatus('committing')
  }

  const handleSavePlanting = ({ saveThenReview = false }) => {
    setServerErrors(null)
    setServerMessage(null)
    setPlantingStatus(saveThenReview ? 'saveThenReview' : 'saving')
  }
  const savingPlanting = ['saveThenReview', 'saving'].includes(plantingStatus)

  // save the planting
  useEffect(() => {
    if (!savingPlanting) return

    const savePlanting = async () => {
      const plannedTreesByPlantingItemId = plannedTrees.reduce((acc, tree) => {
        if (!acc[tree.planting_item_id]) acc[tree.planting_item_id] = []
        acc[tree.planting_item_id].push(tree)
        return acc
      }, {})

      const planting_items_attributes = Object.entries(
        currentPlanting.planting_items
      ).map(([plantingItemKey, plantingItem]) => {
        const itemPlannedTrees = plannedTreesByPlantingItemId[plantingItemKey]
        return {
          ..._pick(plantingItem, [
            'id',
            '_destroy',
            'code',
            'type',
            'genetic_origin_id',
            'genetic_origin_type',
          ]),
          genetic_origin_id: plantingItem.genetic_origin_id,
          genetic_origin_type: plantingItem.genetic_origin_type,
          quantity: itemPlannedTrees?.filter((pt) => !pt._destroy)?.length || 1,
          transgenic_array: (itemPlannedTrees || []).map(
            (tree) => !!tree.transgenic
          ),
          planned_trees_attributes: (itemPlannedTrees || []).map((tree) =>
            _pick(tree, [
              '_destroy', // eslint-disable-line no-underscore-dangle
              'id',
              'space_id',
              'shortcode',
              'name',
              'tag',
              'comments',
              'planted_as',
            ])
          ),
        }
      })

      const formattedData = {
        planting: {
          stage: 'designed',
          name: currentPlanting.name,
          shortcode: currentPlanting.shortcode,
          comments: currentPlanting.comments,
          planted_on: currentPlanting.planted_on,
          orchard_id: currentPlanting.orchard_id,
          planting_type: currentPlanting.planting_type,
          plot_ids: currentPlanting.plot_ids,
          planting_items_attributes,
        },
      }

      try {
        const response = await fetch(`/plantings/${currentPlanting.id || ''}`, {
          method: currentPlanting.id ? 'PUT' : 'POST',
          headers: {
            Accept: 'application/json', // Explicitly request JSON response
            'Content-Type': 'application/json',
            'X-CSRF-Token': form_authenticity_token,
          },
          body: JSON.stringify(formattedData),
        })

        // Check if the fetch was successful
        if (response.status === 422) {
          // Unprocessable Entity
          setPlantingStatus('failed')
          setServerErrors(await response.json())
          return
        }

        if (!response.ok) {
          // If response is not okay, try to parse it as JSON for detailed error messages
          const errorDetails = await response.json()
          throw new Error(`Failed to save planting: ${errorDetails.message}`)
        }

        // If the fetch is successful, parse the JSON response
        const serverResponse = await response.json()
        setServerErrors(null)
        setServerMessage(serverResponse.message)
        window.location = `/plantings/${serverResponse.planting.id}${
          plantingStatus === 'saveThenReview'
            ? '/review_before_commit'
            : '/edit?use_legacy=false'
        }`
      } catch (error) {
        console.error('Error saving planting:', error.message)
        // Handle case where response might not be available or parsable
        setServerErrors({
          message: error.message,
          details:
            error.details || 'Failed to process the request. Please try again.',
        })
        setPlantingStatus('failed')
      }
    }

    savePlanting()
  }, [plantingStatus])

  useEffect(() => {
    if (plantingStatus !== 'committing') return

    const commmitPlanting = async () => {
      try {
        const response = await fetch(
          `/plantings/${currentPlanting.id || ''}/commit`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': form_authenticity_token,
            },
            body: JSON.stringify({}),
          }
        )

        // Check if the response was redirected
        if (response.redirected) {
          // Redirect the browser to the new URL
          window.location.href = response.url
          return
        }

        // Check if the fetch was successful
        if (response.status === 422) {
          // Unprocessable Entity
          setPlantingStatus('failed')
          setServerErrors(await response.json())
          return
        }

        if (!response.ok) {
          // If response is not okay, try to parse it as JSON for detailed error messages
          const errorDetails = await response.json()
          throw new Error(`Failed to save planting: ${errorDetails.message}`)
        }

        // If the fetch is successful, parse the JSON response
        const serverResponse = await response.json()
        setPlantingStatus('')
        setServerMessage(serverResponse.message)
        setServerErrors(null)
      } catch (error) {
        console.error('Error saving planting:', error.message)
        // Handle case where response might not be available or parsable
        setServerErrors({
          message: error.message,
          details:
            error.details || 'Failed to process the request. Please try again.',
        })
        setPlantingStatus('failed')
      }
    }

    commmitPlanting()
  }, [plantingStatus, currentPlanting.id, form_authenticity_token])

  return (
    <div className="SavePlantingControls">
      {serverMessage && <div className="server-message">{serverMessage}</div>}
      <div className="buttons">
        {!reviewMode && !savingPlanting && (
          <>
            <button
              type="button"
              onClick={handleSavePlanting}
              disabled={!plantingHasChanged}
            >
              Save Planting
            </button>
            <button
              type="button"
              onClick={() => handleSavePlanting({ saveThenReview: true })}
            >
              {plantingHasChanged ? 'Continue' : 'Save and Continue'}
            </button>
          </>
        )}
        {reviewMode && !savingPlanting && (
          <>
            <a
              className="back-to-edit"
              href={`/plantings/${currentPlanting.id}/new_edit`}
            >
              Back to Edit
            </a>
            <button
              type="button"
              onClick={handleCommitPlanting}
              className="commit-planting"
            >
              Commit Planting
            </button>
          </>
        )}
      </div>
      {savingPlanting && (
        <div className="saving">
          {plantingStatus === 'committing' ? 'Committing' : 'Saving'}...
        </div>
      )}
      {serverErrors && (
        <div className="server-errors">
          <div>Failed to save planting</div>
          <pre className="serverErrors">
            {JSON.stringify(serverErrors, null, 2)}
          </pre>
        </div>
      )}
    </div>
  )
}

SavePlantingControls.propTypes = {
  currentPlanting: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    shortcode: PropTypes.string,
    comments: PropTypes.string,
    planted_on: PropTypes.string,
    orchard_id: PropTypes.number,
    planting_type: PropTypes.string,
    plot_ids: PropTypes.arrayOf(PropTypes.number),
    planting_items: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        _destroy: PropTypes.bool,
        code: PropTypes.string,
        type: PropTypes.string,
        genetic_origin_id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        genetic_origin_type: PropTypes.string,
      })
    ),
  }).isRequired,
  form_authenticity_token: PropTypes.string.isRequired,
  plannedTrees: PropTypes.arrayOf(
    PropTypes.shape({
      planting_item_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      _destroy: PropTypes.bool,
      transgenic: PropTypes.bool,
    })
  ).isRequired,
  plantingHasChanged: PropTypes.bool.isRequired,
  reviewMode: PropTypes.bool.isRequired,
}

export default SavePlantingControls
