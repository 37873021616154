.InputWithClear {
  position: relative;
}
.InputWithClear input {
  padding-right: 50px;
  width: 100%;
}
.InputWithClear .clear-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-35%);
  background: none;
  border: none;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF84ZWZhMWQzNC9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL2FwcGxpY2F0aW9uIiwic291cmNlcyI6WyJJbnB1dFdpdGhDbGVhci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7O0FBRUE7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuSW5wdXRXaXRoQ2xlYXIge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG5cbiAgaW5wdXQge1xuICAgIHBhZGRpbmctcmlnaHQ6IDUwcHg7XG4gICAgd2lkdGg6IDEwMCU7XG4gIH1cblxuICAuY2xlYXItaWNvbiB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHJpZ2h0OiA1cHg7XG4gICAgdG9wOiA1MCU7XG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKC0zNSUpO1xuICAgIGJhY2tncm91bmQ6IG5vbmU7XG4gICAgYm9yZGVyOiBub25lO1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgfVxufVxuIl19 */