.TreeMarker {
  cursor: pointer;
}
.TreeMarker img {
  height: 50px;
  margin-left: -19px;
  margin-top: -50px;
  opacity: 0.6;
}
.TreeMarker img.active {
  opacity: 1;
}
.TreeMarker .pin-text {
  color: white;
  margin-top: -40px;
  margin-left: -3px;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF84ZWZhMWQzNC9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3BsYW50aW5nIiwic291cmNlcyI6WyJUcmVlTWFya2VyLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCIuLi8uLi8uLi9hc3NldHMvc3R5bGVzaGVldHMvY29sb3JzXCI7XG5cbi5UcmVlTWFya2VyIHtcbiAgY3Vyc29yOiBwb2ludGVyO1xuXG4gIGltZyB7XG4gICAgaGVpZ2h0OiA1MHB4O1xuICAgIG1hcmdpbi1sZWZ0OiAtMTlweDtcbiAgICBtYXJnaW4tdG9wOiAtNTBweDtcbiAgICBvcGFjaXR5OiAwLjY7XG4gIH1cblxuICBpbWcuYWN0aXZlIHtcbiAgICBvcGFjaXR5OiAxO1xuICB9XG5cbiAgLnBpbi10ZXh0IHtcbiAgICBjb2xvcjogd2hpdGU7XG4gICAgbWFyZ2luLXRvcDogLTQwcHg7XG4gICAgbWFyZ2luLWxlZnQ6IC0zcHg7XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIH1cbn1cbiJdfQ== */