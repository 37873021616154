// A family of inputs that can be used with or without the optional label prop
//  - onChange functions will always return the new value (NOT the event)
//  - inputs that accept options will take options in any of the following formats
//    - array of single elements: ['Orange', 'Green']
//    - array of label/value: [['Label 1', 'key1'], ['Label 2', 'key2']]
//    - array of objects: [{ label: 'Label', value: 'val' }]

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _uniqueId from 'lodash/uniqueId'
import Select from 'react-select'
import './OrgInputs.scss'

const checkForDuplicateOptions = (formattedOptions, identifier) => {
  const seen = new Set()
  const duplicates = []

  formattedOptions.forEach((formattedOption) => {
    const value = formattedOption?.value

    if (value !== undefined) {
      const normalizedValue = String(value) // Normalize all values to strings
      if (seen.has(normalizedValue)) duplicates.push(normalizedValue)
      else seen.add(normalizedValue)
    }
  })
  if (seen.size === 0) console.warn(`No options found for '${identifier}'`)
  if (duplicates.length > 0)
    console.warn(`Duplicate values for ${identifier}: ${duplicates.join(', ')}`)
}

const getOrgInputClassName = ({
  specificClass,
  singleRow = false,
  hidden = false,
}) =>
  `OrgInput ${specificClass} ${singleRow ? 'single-row' : ''} ${
    hidden ? 'hidden' : ''
  }`

const isInReactSelectFormat = (v) => v instanceof Object && !Array.isArray(v)
const formatOption = (option) => {
  if (!option) return null
  if (isInReactSelectFormat(option)) return option
  if (Array.isArray(option) && option.length === 2)
    return { value: option[1], label: option[0] }
  return { value: option, label: option }
}
const unformatOptions = (options) =>
  Array.isArray(options)
    ? options.map((o) => o.value)
    : options && options.value

// The react-select wants us to pass in the ENTIRE value/label object to be selected, not just the value.
// However, I don't want to store its special object format. We can just retrieve it with the value here.
const getValue = (allOptions, value) => {
  if (!value) return ''

  const findSingleValue = (v) =>
    // intentional == instead of ===; we want to match on '1' to 1
    // eslint-disable-next-line eqeqeq
    allOptions.find((op) => op.value == formatOption(v).value)

  if (
    isInReactSelectFormat(value) ||
    (Array.isArray(value) && value.every((el) => isInReactSelectFormat(el)))
  )
    return value
  // no need to convert; it's already in the expected format

  // eslint-disable-next-line no-console
  if (!Array.isArray(allOptions)) return console.warn('allOptions missing')

  if (Array.isArray(value)) return value.map((elem) => findSingleValue(elem))
  // this must be an isMulti select; we need to return an array of values

  return findSingleValue(value) || ''
}

const computeExpandsOnOpenStyles = (margin) => ({
  control: (baseStyles, _state) => ({
    ...baseStyles,
    fontSize: '14px',
    fontFamily: 'MetaPro',
    lineHeight: '18px',
    color: '#454545',
    backgroundColor: '#fdfdfd',
    borderColor:
      _state.selectProps.className.indexOf('unvalid') > -1
        ? '#c54261'
        : baseStyles.borderColor,
    '&:hover': {
      borderColor:
        _state.selectProps.className.indexOf('unvalid') > -1
          ? '#c54261'
          : baseStyles.borderColor,
    },
    marginBottom: _state.isFocused ? margin : 0,
  }),
})

const OrgInputLabel = ({ className, inputId, label }) => {
  if (!label) return ''

  return (
    <label
      className={`OrgInputLabel ${className}`}
      htmlFor={inputId || undefined} // undefined prevents empty 'for' attribute on html element
    >
      {label}
    </label>
  )
}

export const OrgSelectInput = ({
  disabled,
  defaultValue,
  expandsOnOpen,
  expandsOnOpenMargin,
  hidden = false,
  inputId,
  isClearable = true,
  isMulti,
  isValueDisabled,
  label,
  name,
  onChange,
  onKeyDown,
  options,
  placeholder,
  singleRow = false,
  value,
}) => {
  const [_isMenuOpen, _setIsMenuOpen] = useState() // observing react-select internal state

  const inputDomId = inputId || _uniqueId('org-select-input-')
  const formattedOptions = (options || []).map((opt) => formatOption(opt))
  const maybeIsOptionDisabled = isValueDisabled
    ? { isOptionDisabled: (option) => isValueDisabled(option.value) }
    : {}

  const blankOption = formattedOptions.find((option) => option.value === '')
  if (blankOption && placeholder)
    console.warn(
      "Provide a placeholder or an option with value='' but not both"
    )

  if (!disabled)
    checkForDuplicateOptions(formattedOptions, label || name || inputDomId)

  const styles =
    expandsOnOpen && _isMenuOpen
      ? computeExpandsOnOpenStyles(expandsOnOpenMargin)
      : undefined

  return (
    <div
      className={getOrgInputClassName({
        specificClass: 'select',
        singleRow,
        hidden,
      })}
    >
      <OrgInputLabel label={label || ''} inputId={inputDomId} />
      <Select
        defaultValue={defaultValue && formatOption(defaultValue)}
        inputId={inputDomId}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        name={name}
        onChange={
          onChange && ((newValue) => onChange(unformatOptions(newValue)))
        }
        onKeyDown={onKeyDown}
        onMenuClose={() => _setIsMenuOpen(false)}
        onMenuOpen={() => _setIsMenuOpen(true)}
        options={formattedOptions}
        placeholder={placeholder || blankOption?.label}
        styles={styles}
        value={value && getValue(formattedOptions, value)}
        {...maybeIsOptionDisabled}
      />
      {false && <span className="warning errors">errors</span>} {/* TODO */}
    </div>
  )
}

OrgSelectInput.defaultProps = {
  disabled: false,
  expandsOnOpen: false,
  expandsOnOpenMargin: '210px',
  hidden: false,
  inputId: undefined,
  isClearable: true,
  isMulti: false,
  isValueDisabled: undefined,
  label: '',
  name: '',
  onChange: undefined,
  onKeyDown: undefined,
  placeholder: undefined,
  singleRow: false,
  value: '',
}

const optionType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
])

OrgSelectInput.propTypes = {
  disabled: PropTypes.bool,
  expandsOnOpen: PropTypes.bool,
  expandsOnOpenMargin: PropTypes.string,
  hidden: PropTypes.bool,
  inputId: PropTypes.string,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isValueDisabled: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  options: PropTypes.arrayOf(optionType).isRequired,
  placeholder: PropTypes.string,
  singleRow: PropTypes.bool,
  value: optionType,
}
